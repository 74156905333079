.App {
  text-align: center;
  color: white;
}

#App-logo {

  height: 5vmin;
  width: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


/* filler */

.Navi {
  color: white;
  
}

.Title {
  color:white;
  font-family: 'Times New Roman', Times, serif;
  vertical-align: middle;
}

.centerAlign{
  text-align: center;
  vertical-align:  middle;
  color:white
}

#Groceries {
  text-align: center;
}

#detailTitle {
  text-align: center
}

#addTaskBox {
    min-width: 200px;
}

/* #inputBox {
  min-width: 150px;
} */

/* Main */

.appBody {
  
  background-size: cover;
  background-color: black;
}

.comBody {
 /* background-color: #4F5B5E; */
 background-color: #282828;
 border-radius:5px;
 color:white
}

html {
  background-color:black;
  /* background-image: url("./images/arianight.png");
  background-position: stretch;
  background-repeat: no-repeat; */
}

a#animeLinks:link {
  color:white;
  text-decoration: underline;
}

/* visited link */
a#animeLinks:visited {
  color: white;
}


/* Components */
#home {
  background-color: transparent;
  padding: 20px
}

#homeProgress {
  background-color: transparent;
  text-align: center;
  color:white
}

.text-center-Login {
  color:white;
  text-align: center;

}

#login {
  height: 50vh;
}

#loginCss{
  color: rgb(0,255,255);
  background-color: black
}

#dropLink {
  color:black
}

#profile {
  position: relative;
  float: right;
  padding-right: 5px;
}

.navbar-dark {
    width: 100%;
    white-space: nowrap;
}

.Form {
  width: 40%;
}

#tasksDone {
  text-decoration: line-through;
  font-size: medium;
}

#tasks {
  font-size: medium;
}

 /* JumboTron */

#jumbo {
  background-color:lightblue;
  color: white;
  height: auto;
  text-align: center;
  background-image: url("./images/night.jpg");
  background-size: auto;
  background-repeat: no-repeat;
}

#jumboText {
  
  font-size:8vw;
}

#jumboGreeting {
  font-size:2vw;
}


.textCenter {
  color:white;
  text-align: center
}

#assignedTo{
  color:red;
  text-align: center;
}


.scrollTable {
  display:block;
overflow: auto;
height:500px
}



.buttonGroup {
  position: relative;
  float: right;
  padding:1px
}


#title {
  position: absolute;
  color:white 
}

.table-wrapper-scroll-y {
 
  max-height: 500px;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}


/* Anime Component */

#animeList {
  color: white; 
}









/* Carousel Tests */
.carousel-item, .carousel-item.active {
  align-items:center;
}

.carousel-control-prev,.carousel-control-next {
  cursor:pointer;
}

.carousel-item > img { 
  width: 100%; 
}


#projectCard {
    color:black;
    background-color: #282828;
  display: flex;
  justify-content: center;
}


.signInComponent {
  color:black;
  background-color: #282828;
  display: flex;
  justify-content: center;
  
  
}


.cardTextLong {
 
  max-width: 397px;
  overflow-wrap: break-word;
  
  
}

#carouselPic {

  width: 100%;
  height: auto;
  
  min-width : 200px;
  max-width : 640px;
  max-height: 340px;
}
